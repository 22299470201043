
$md-color-primary: #5a5a5a; 
$md-color-secondary:#CB711D;

// Basic Colors
$navy: #CB711D;       // Primary color
$dark-gray: #c2c2c2;  // Default color
$blue: #2177D5;       // Success color
$lazur: #494949;      // Info color
$green: rgb(10, 138, 21);      // Warning color
$orange: rgb(212, 129, 19);     // Warning color
$yellow: #8F9799;     // Normal info color
$red: rgb(184, 19, 19);        // Danger color

// Various colors
$background-color: #EDEDEE;
$text-color: #515151;   // Body text
$gray: #ECEEF4;         // Background wrapper color
$light-gray: #D1DADE;   // Default label, badge
$label-badge-color: #5E5E5E;
$light-blue:#f3f6fb;

// Spiner color and margin
$spin-color: $navy;
$spin-margin: 0 auto;

// IBOX colors ( default panel colors)
$border-color: #e7eaec;     // IBox border
$ibox-title-bg:#ffffff;     // IBox Background header
$ibox-content-bg:#ffffff;   // IBox Background content

// Menu

/*

$menu-background-active: #fff;
$menu-background: #fff; 
$menu-color: #515151;
$menu-color-active: #515151;
$menu-border-bottom: 1px solid #f0f0f0; 

$md-color-primary: #434343; // 385D91
$menu-background-active: #606060;
$menu-background: #535353;
$menu-color: #fff; // 515151
$menu-color-active: #fff;
$menu-border-bottom: 1px solid #555; //'1px solid #f0f0f0';

*/

//Sidebar width
$sidebar-width: 240px; 

// Assets path
$asset-path : "./../patterns";

// Boxed layout width
$boxed-width: 1200px;
$boxed-background: url('#{$asset-path}/shattered.png');

//Border radius
$btn-border-ibox: 5px; 
$btn-border-radius: 3px; 

//Navigation
$nav-bg: #2F4050;
$nav-text-color: #a7b1c2;
