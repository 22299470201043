/* iCheck plugin Minimal skin, grey
----------------------------------- */
// Assets path
$asset-path : "./../../images";

.icheckbox_minimal-grey,
.iradio_minimal-grey {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 18px;
    height: 18px;
    background: url('#{$asset-path}/icons/grey.png') no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_minimal-grey {
    background-position: 0 0;
}
    .icheckbox_minimal-grey.hover {
        background-position: -20px 0;
    }
    .icheckbox_minimal-grey.checked {
        background-position: -40px 0;
    }
    .icheckbox_minimal-grey.disabled {
        background-position: -60px 0;
        cursor: default;
    }
    .icheckbox_minimal-grey.checked.disabled {
        background-position: -80px 0;
    }

.iradio_minimal-grey {
    background-position: -100px 0;
}
    .iradio_minimal-grey.hover {
        background-position: -120px 0;
    }
    .iradio_minimal-grey.checked {
        background-position: -140px 0;
    }
    .iradio_minimal-grey.disabled {
        background-position: -160px 0;
        cursor: default;
    }
    .iradio_minimal-grey.checked.disabled {
        background-position: -180px 0;
    }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
    .icheckbox_minimal-grey,
    .iradio_minimal-grey {
        background-image: url('#{$asset-path}/icons/grey@2x.png') no-repeat;
        -webkit-background-size: 200px 20px;
        background-size: 200px 20px;
    }
}