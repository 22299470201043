/*
 *
 *   360Locate
 *   version 0.0.01
 *
*/
// Google Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

// Variables, Mixins
@import "imports/variables";
@import "imports/mixins";

// 360Locate Theme Elements
@import "imports/typography";
@import "imports/navigation";
@import "imports/top_navigation";
@import "imports/buttons";
@import "imports/badges_labels";
@import "imports/elements";
@import "imports/sidebar";
@import "imports/base";
@import "imports/pages";
@import "imports/chat";
@import "imports/metismenu";
@import "imports/spinners";

// Landing page styles
@import "imports/landing";

// RTL Support
@import "imports/rtl";

// For demo only - config box style
@import "imports/theme-config";

// 360 Skins
@import "imports/locate360theme";

// Bootstrap xl
@import "imports/bootstrap_xl";

@import "imports/icheck";

// Media query style
@import "imports/media";

// Custom style
// Your custom style to override base style
@import "imports/custom";

// Materialise
@import "imports/waves";

// Clear layout on print mode
@media print {
  nav.navbar-static-side {
    display: none;
  }
  body { overflow: visible !important; }

  #page-wrapper {
    margin: 0;
  }
}
